import { functions } from "../firebaseConfig"; // Assuming firebaseConfig correctly exports initialized Firebase app
import { httpsCallable } from "firebase/functions";


export const getOrders = async (filters = {}) => {
    const getOrdersCallable = httpsCallable(functions, "getOrders");
  
    let startDate = filters.startDate ? new Date(filters.startDate) : null;
    let endDate = filters.endDate ? new Date(filters.endDate) : null;
  
    if (startDate) {
      // Ensure start date is at 00:00:00.000
      startDate.setHours(0, 0, 0, 0);
      startDate = startDate.toISOString();
    }
  
    if (endDate) {
      // Ensure end date is at 23:59:59.999
      endDate.setHours(23, 59, 59, 999);
      endDate = endDate.toISOString();
    }
  
    const requestData = {
      detailed: true,
      startDate: startDate || null,
      endDate: endDate || null,
      orderStatus: filters.orderStatus || null,
      companyId: filters.companyId || null,
    };
  
    // Call the function with filters
    const response = await getOrdersCallable(requestData);
    return response.data;
  };
  
  

export const getOrderById = async (orderId) => {
    try {
        const getOrderByIdCallable = httpsCallable(functions, "getOrderById");
        const response = await getOrderByIdCallable({ orderId });

        if (response.data && response.data.order) {
            return response.data.order;
        } else {
            throw new Error("Order data not found in the response");
        }
    } catch (error) {
        console.error("Error fetching order:", error);
        throw error;
    }
};

export const updateOrderV2 = async (orderId, updatedOrder) => {
    try {
        // Create a reference to the Firebase Cloud Function
        const updateOrderV2Function = httpsCallable(functions, "updateOrderV2");

        // Call the Cloud Function and pass the data
        const response = await updateOrderV2Function({ orderId, updatedOrder });

        if (response.data.success) {
            return true;
        } else {
            console.error("Failed to update order:", response.data.message);
            return false;
        }
    } catch (error) {
        console.error("Error calling updateOrderV2:", error);
        return false;
    }
};

export const addPackedItems = async (payload) => {
    //console.log("Payload before sending:", JSON.stringify(payload, null, 2));

    try {
        const addPackedItemsCallable = httpsCallable(functions, "addPackedItems");

        // Pass all payload fields dynamically
        const response = await addPackedItemsCallable(payload);

        // Handle successful response
        if (response.data.success) {
            return response.data;
        } else {
            throw new Error("Failed to update packed items.");
        }
    } catch (error) {
        throw error;
    }
};


export const updateOrderStatus = async (orderId, orderStatus, paymentMethod) => {
    try {
        const updateOrderStatus = httpsCallable(functions, "updateOrderStatus");
        const response = await updateOrderStatus({
            orderId,
            updatedOrder: {
                orderStatus,
                paymentMethod,
            },
        });

        if (response?.data?.success) {
            //console.log("Order updated successfully:", response.data.message);
            return response.data;
        } else {
            throw new Error(response.data.message || "Failed to update order");
        }
    } catch (error) {
        console.error("Error updating order status:", error);
        throw error;
    }
};

export const updateProducerOrderStatus = async (orderId, producerOrderId, producerOrderStatus) => {
    try {
        // Create a reference to the Firebase callable function
        const updateProducerOrderStatus = httpsCallable(functions, "updateProducerOrderStatus");

        // Call the function with the required parameters
        const response = await updateProducerOrderStatus({
            orderId,
            producerOrderId,
            producerOrderStatus,
        });

        // Check the response from the callable function
        if (response?.data?.success) {
            //console.log("Producer order status updated successfully:", response.data.message);
            return response.data;
        } else {
            throw new Error(response.data.message || "Failed to update producer order status");
        }
    } catch (error) {
        console.error("Error updating producer order status:", error);
        throw error;
    }
};

export const updateFinalOrderSummary = async (orderId) => {
    try {
        // Create a reference to the Firebase callable function
        const updateFinalOrderSummary = httpsCallable(functions, "updateFinalOrderSummary");

        // Call the function with the required parameters
        const response = await updateFinalOrderSummary({ orderId });

        // Check if the function returned success
        if (response?.data?.success) {
            //console.log("Final order summary updated successfully:", response.data);
            return response.data; // Return the final order summary
        } else {
            throw new Error(response.data?.message || "Failed to update the final order summary.");
        }
    } catch (error) {
        console.error("Error updating final order summary:", error);
        throw error; // Rethrow the error for further handling
    }
};
